<template>
  <div :class="$style.root">
    <VListItem two-line href="/">
      <VListItemAvatar size="small">
        <VImg v-if="src" :src="src" />
        <VIcon dark>mdi-account-circle</VIcon>
      </VListItemAvatar>
      <VListItem-content>
        <VListItemTitle v-if="name">{{name}}</VListItemTitle>
        <VListItemSubtitle v-if="description" :title="description">{{description}}</VListItemSubtitle>
      </VListItem-content>
    </VListItem>
  </div>
</template>

<script>

export default {
  name: 'UserThumbnail',
  props: {
    name: { type: String },
    description: { type: String},
    src: { type: String },
  },
  computed: {},
}
</script>

<style module lang="scss">
.root {
  :global(.v-list-item__subtitle) {
    width: 120px;
  }
}
</style>
