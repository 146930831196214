<template>
  <VList>
    <VListItem v-for="{ id, text } in accounts" :key="id" link @click.prevent="() => choseAccount(id)">
      <VListItemTitle>{{ text }}</VListItemTitle>
    </VListItem>
  </VList>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { first } from 'lodash-es';
export default {
  name: 'UserChoseAccount',
  computed: {
    ...mapGetters({
      loading: 'user/pending',
      user: 'user/current',
      accounts: 'user/accounts',
    }),
  },
  methods: {
    ...mapActions({
      setAccount: 'user/setAccount',
      fetchCurrent: 'user/fetchCurrent',
    }),
    async choseAccount(id) {
      const res = await this.setAccount({ id });
      if (first(res) === 'success') {
        await this.fetchCurrent();
        this.$emit('setAccount:success', res);
      }
      else this.$emit('setAccount:error', res);
    }
  },
}
</script>

<style module lang="scss">
.root {
  max-width: 400px;
  margin: auto;
}
.nav {
  margin: auto;
  max-width: 400px;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
