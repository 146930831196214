import Vue from 'vue';
import axios from 'axios';
import { get, merge, reduce, isObject, forEach, isArray, omitBy, isEmpty } from 'lodash-es';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const fetch = axios.create({
  method: 'POST',
  baseURL: process.env.VUE_APP_REST_ROOT || '',
});
// Всегда подставляем sessid к запросу
fetch.interceptors.request.use(function (config) {
  // Если это не запрос на разлогирование всегда добавляем сессию к запросу
  if (get(config, 'params.logout') !== 'yes') {
    merge(config, { params: { sessid: Api.sessid }});
  }
  return config;
}, Promise.reject);
// Обработка ответов для актуализации sessid;
fetch.interceptors.response.use((response) => {
  // const next = get(response, 'data.sessid', get(response, 'data.auth.sessid'));
  // if (next) Api.sessid = next;
  return response;
},  (error) => {
  const response = error?.response;
  const next = get(response, 'data.sessid', get(response, 'data.auth.sessid'));
  if (next) Api.sessid = next;
  return Promise.reject(error);
});

class Api {
  // sessid по умолчанию
  static sessid =  IS_PRODUCTION ? window.bx_sessid : process.env.VUE_APP_SESSID || ' ';

  constructor(root) {
    this.base = root || process.env.VUE_APP_REST_ROOT || '';
    if (this.base.indexOf('http') !== 0) {
      this.base = window.location.origin + this.base
    }
  }

  logger = (error, notifyDuration) => {
    Vue.notify({
      type: 'error',
      title: get(error, 'error'),
      text: get(error, 'error_description', get(error, 'result.message', get(error, 'message', ''))),
      duration: notifyDuration,
    });
    if (!IS_PRODUCTION) console.warn(error);
  };

  rest = (method, options, notifyDuration = 30000, noLogger = false) => {
    const url = this.base + method;
    return fetch(url, options)
      .then(response => get(response, 'data.result'))
      .catch(response => {
        if (!noLogger) this.logger(get(response, 'response.data', response), notifyDuration);
        throw new Error(
          JSON.stringify({
            message: get(response, 'message', ""),
            status: get(response, 'response.status', ""),
            data: get(response, 'response.data', ""),
          }));
    })
  }

  /**
   * Эмуляция клика по ссылке с атрибутом download
   */
  download = async (method, options) => {
    const url = this.base + method;
    const requestOptions = merge({ params: { sessid: Api.sessid } }, options);
    const link = document.createElement('a');
    requestOptions.params = reduce(requestOptions.params, (acc, cur, key) => {
      if (isObject(cur) || isArray(cur)) forEach(omitBy(cur, isEmpty), (val, index) => {
        acc[`${key}[${index}]`] = val;
      });
      else acc[key] = cur;
      return acc;
    }, {});
    const href = url + `?${new URLSearchParams(requestOptions.params).toString()}`;
    link.setAttribute('download', name || 'file');
    link.setAttribute('href', href);
    link.click();
  }
  checkSession = async () => {
    return fetch('/bitrix/tools/public_session.php', { baseURL: '/' })
    .then((response) => {
      if (response.data === 'OK') return true;
      Vue.notify({
        type: 'error',
        group: 'checkSession',
        title: 'Сессия устарела',
        text: 'Ваш сеанс работы с сайтом завершен из-за отсутствия активности. Введенные на странице данные не будут сохранены. Скопируйте их перед тем, как закроете или обновите страницу. Нажмите F5',
        duration: -1,
      });
    });
  }
}

export default Api;
