<template>
  <v-navigation-drawer v-if="items.length" :class="$style.root" app permanent expand-on-hover width="350">
    <v-list>
      <v-list-item class="px-2" two-line href="/">
        <v-list-item-avatar>
          <v-icon large v-text="'mdi-account-circle'" />
        </v-list-item-avatar>
        <v-list-item-content class="pl-3">
          <v-list-item-title v-text="name" />
          <v-list-item-subtitle v-text="description" />
        </v-list-item-content>
        <v-list-item-action @click.prevent="logout">
          <v-icon v-text="'mdi-exit-to-app'" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="accounts.length" :class="$style.item" @click="() => choseDialog = true">
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-convert'" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap" v-text="'Изменить аккаунт организации'" />
        </v-list-item-content>
        <UserChoseDialog can-close v-model="choseDialog" @setAccount:success="() => this.$router.push({ name: 'user/ProfileView' })"/>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list-item-group :value="selected" color="primary" >
      <v-list-item v-for="(item, i) in items" :class="$style.item" :href="item.link" :key="i">
        <v-list-item-icon>
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-wrap" v-text="item.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { findKey } from 'lodash-es';
import UserChoseDialog from '@/views/user/UserChoseDialog/UserChoseDialog.vue';

export default {
  name: 'MainMenuView',
  components: { UserChoseDialog },
  data() {
    return {
      choseDialog: false,
    };
  },
  props: {
    name: { type: String },
    description: { type: String},
    src: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'general/pending',
      items: 'general/menu',
      accounts: 'user/accounts',
    }),
    selected() {
      return findKey(this.items, { selected: true });
    }
  },
  methods: {
    ...mapActions({
      fetchMenuItems: 'general/fetchMenuItems',
    }),
    logout() {
      //TODO: поменять на рест метод
      window.location = '/?logout=yes';
    },
  },
  mounted() {
    this.fetchMenuItems();
  }
}
</script>

<style module lang="scss">
.root :global(.v-navigation-drawer__content:not(:hover)) {
  overflow: hidden;
}

.root :global(.v-navigation-drawer__content .v-list-item__icon) {
  align-self: center;
}

.item {
  width: 350px;
  min-height: 63px;
}
</style>
