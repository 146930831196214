<template>
  <div :class="$style.root">
    <template v-if="user && !hasSelectedAccount">
      <UserChoseDialog v-model="choseDialog"/>
    </template>
    <template v-else>
      <div :class="$style.nav">
        <VRow dense>
          <VCol cols="6">
            <VBtn color="primary" width="100%" large depressed>Авторизация</VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn color="primary" width="100%" large depressed outlined :to="{name: 'user/UserRegistrationView'}">Регистрация</VBtn>
          </VCol>
        </VRow>
      </div>
      <div :class="$style.inner">
        <VForm v-model="valid" lazy-validation ref="form" class="auth-form" @submit.prevent="onSubmit">
          <VRow>
            <VCol cols="12">
              <div :class="$style.label">
                E-mail - для контрагентов <br>
                AD login - для сотрудников ЕВРАЗ
              </div>
              <VTextField v-model="login" :rules="rules.required" :disabled="loading" outlined dense hide-details="auto" name="USER_LOGIN" autocomplete="username" />
            </VCol>
            <VCol cols="12">
              <router-link :to="{ name: 'user/UserForgotView' }" class="float-right">Забыли пароль?</router-link>
              <div :class="$style.label">Пароль</div>
              <VTextField v-model="password" :rules="rules.required" :disabled="loading" type="password" outlined dense hide-details="auto" name="USER_PASSWORD" autocomplete="current-password" />
            </VCol>
            <VCol cols="12">
              <VBtn color="primary" width="100%" large type="submit" depressed :loading="loading">Войти</VBtn>
            </VCol>
            <VCol>
              <VDialog max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <div :class="$style.control">
                    Авторизуясь, я соглашаюсь с условиями <div :class="$style.link" v-bind="attrs" v-on="on">Пользовательского соглашения</div>
                  </div>
                </template>
                <AgreeCard />
              </VDialog>
            </VCol>
          </VRow>
        </VForm>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AgreeCard from '@/components/general/AgreeCard/AgreeCard';
import UserChoseDialog from '@/views/user/UserChoseDialog/UserChoseDialog.vue';
export default {
  name: 'UserLoginView',
  components: {
    UserChoseDialog,
    AgreeCard,
  },
  data() {
    return {
      valid: true,
      login: '',
      password: '',
      choseDialog: true,
      rules: {
        required: [(v) => !!v || 'Обязательное поле'],
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'user/pending',
      user: 'user/current',
      hasSelectedAccount: 'user/hasSelectedAccount',
    }),
  },
  methods: {
    ...mapActions({
      loginUser: 'user/loginUser',
    }),
    onSubmit: function() {
      if (this.$refs.form.validate()) {
        const login = this.login;
        const password = this.password;
        const params = new URLSearchParams(window.location.search);
        const portal = params.get('portal');
        const redirect = params.get('redirect');
        this.loginUser({
          login,
          portal,
          password,
          redirect,
        });
      }
    },
  },
  watch: {
    user: {
      immediate: true,
      handler: async function() {
        if (this.hasSelectedAccount) this.$router.push({ name: 'user/ProfileView' });
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  max-width: 400px;
  margin: auto;
}
.nav {
  margin: auto;
  max-width: 400px;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
